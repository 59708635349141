import React from 'react'
import '../../styles/Footer.css'

function Footer() {
    return (
        <div className='Footer'> 
            <div className="container">
                <div className="logo">
                    <img src="img/Chiccone_Logo__white.png" alt="logo" />
                </div>
                <div className="links">
                    <div className="section">
                        <ul>
                            <li>
                                <a href="/">Home</a>
                            </li>
                            <li>
                                <a href="/about">About</a>

                            </li>
                            <li>
                                <a href="/contact">Contact</a>

                            </li>

                        </ul>
                    </div>


                </div>
                <div className="address">
                    <div className="details">

                        <p><i className="fa-solid fa-location-dot"></i>Busaiteen, Bahrain</p>
                        <p><i className="fa-solid fa-location-dot"></i>Street Store, Unnichira,<br/> Koonamthai, Edappally,<br/> Ernakulam, Kerala<br/> 682024</p>
                        
                    </div>
                    <div className="social-media">
                        <a href="https://www.facebook.com/p/Chiccone-100085913791359/" target="_blank" rel="noopener noreferrer"><span className="social-media"><i
                            className="fa-brands fa-facebook"></i></span></a>
                        <a href="https://www.instagram.com/chiccone_india/" target="_blank" rel="noopener noreferrer"><span className="social-media"><i
                            className="fa-brands fa-instagram"></i></span></a>
                        <a href="mailto:info@chiccone.in"><span className="social-media"><i class="fa-solid fa-envelope"></i></span></a>

                    </div>

                </div>
            </div>
            <div className="copyright">
                <p>© Copyright - Chiccone</p>
            </div>
        </div>
    )
}

export default Footer