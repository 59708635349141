import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import '../../styles/GetInTouch.css';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import ReCAPTCHA from "react-google-recaptcha";

function GetInTouch() {

    const [verified, setVerified] = useState(false);

    function onChange(value) {
        console.log("Captcha value:", value);
        setVerified(true);
    }


    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_nn0bg4o', 'template_o6cd4zs', form.current, 'Zcgg843Tbglj5v6CO')
            .then((result) => {
                console.log(result.text);
                console.log("message sent");
                toast.success('Message Sent! Your message has been sent successfully.');

            }, (error) => {
                console.log(error.text);
                toast.error('An error occurred. Please try again!');

            });
    };
    return (
        <div className='GetInTouch'>
            <div className="wave-layer"></div>
            <div className="container">
                <h2 data-aos="fade" data-aos-duration="1000">Get In Touch!</h2>
                <div className="row">
                    <div className="col custom-fade-right" data-aos="custom-fade-right" data-aos-duration="1000">
                        <h3>Give Us A Call</h3>
                        <div className="text flex-direction">
                            <span className='phone-span'><i className="fa-solid fa-phone"></i><a href="tel:1367 4555 3757 4555"><p>1367 4555 3757 4555</p></a></span>
                            <span className='phone-span'><i className="fa-solid fa-phone"></i><a href="tel:+91 98465 88888"><p>+91 98465 88888</p></a></span>
                        </div>
                        

                        <h3>Send Us An Email</h3>
                        <div className="text">

                            <span><i className="fa-solid fa-envelope"></i></span><a href="mailto:info@chiccone.in"><p>info@chiccone.in</p></a>
                        </div>

                        <h3>Location</h3>
                        <div className="text">
                            <span><i className="fa-solid fa-location-dot"></i></span>
                            <p>Busaiteen, Bahrain</p>
                        </div>
                        <div className="text kochi">
                            <span><i className="fa-solid fa-location-dot"></i></span>
                            <p>Street Store, Unnichira,<br/> Koonamthai, Edappally,<br/> Ernakulam, Kerala<br/> 682024</p>
                        </div>
                    </div>
                    <div className="col form custom-fade-left" data-aos="custom-fade-left" data-aos-duration="1000">
                        <form ref={form} onSubmit={sendEmail}>
                            <div>
                                <input type="text" id="name" name='name' placeholder='Name'required />
                            </div>
                            <div>
                                <input type="email" id="email" name='email' placeholder='Email' required/>
                            </div>
                            <div>
                                <input type="text" id="phone" name='phone' placeholder='Phone'required />
                            </div>
                            <div>
                                <textarea id="message" name='message' placeholder='Message' required/>
                            </div>
                            <ReCAPTCHA
                                sitekey="6Lcy0nApAAAAAGv1uHShiG16Hy68AcVsyop3z7lz"
                                onChange={onChange}
                            />
                            <button type="submit" value="Send" disabled={!verified}>Submit</button>

                        </form>
                    </div>
                </div>
            </div>
            <ToastContainer
                position="bottom-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="dark"
            />
        </div>
    )
}

export default GetInTouch